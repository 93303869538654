import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import TermsPopup from '@components/main/TermsPopup/TermsPopup';

export const Footer: FC = () => {
  const [showPopup, setShowPopup] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <TermsPopup isOpen={showPopup} toggle={() => setShowPopup(!showPopup)} />
      <SWrapper>
        <SC onClick={() => setShowPopup(!showPopup)}>
          <div>{t('footer.logo_text')}</div>
          <div>© 2023 Fincollect SE</div>
        </SC>
      </SWrapper>
    </>
  );
};

const SWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const SC = styled.h5`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.BODY};
  right: 50%;
  left: calc(50% - 150px);
  text-align: center;
  width: 300px;
  word-break: keep-all;
  transition: all;
  transition-duration: 0.3s;

  &:hover {
    font-size: 14px;
  }
`;
